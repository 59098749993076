import { createPopper } from '@popperjs/core';
import bootstrap from 'bootstrap';

import Swiper, { Navigation, Pagination } from 'swiper';
// import Swiper and modules styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

Swiper.use([Navigation, Pagination]);

const heroSlider = new Swiper('.hero__bg .swiper', {
    slidesPerView: 1,
    autoplay: {
        delay: 2500
    }
});

const profileSwiper = new Swiper('.services-featured__swiper .swiper', {
    navigation: {
        nextEl: '.services-featured__swiper-navigation-next',
        prevEl: '.services-featured__swiper-navigation-prev',
    },
    pagination: {
        el: '.services-featured__swiper-pagination',
        type: 'bullets',
        clickable: true
    },
    slidesPerView: 'auto',
    spaceBetween: 24,
    watchOverflow: true,
});

const referenceSwiper = new Swiper('.references__swiper .swiper', {
    pagination: {
        el: '.references__swiper-pagination',
        type: 'bullets',
        clickable: true
    },
    centerInsufficientSlides: true,
    slidesPerView: 2,
    spaceBetween: 24,
    breakpoints: {
        480: {
            slidesPerView: 2,
            spaceBetween: 16
        },
        768: {
            slidesPerView: 4,
            spaceBetween: 20
        },
        992: {
            slidesPerView: 6,
            spaceBetween: 24
        },
    },
    watchOverflow: true,
});